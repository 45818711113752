$theme-colors: (
    "fatal": #000000,
    "critical": #ff063c,
    "alert": #ff9d00
);

@import "~bootstrap/scss/bootstrap";

.btn-dark {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    &:hover {
        color: #fff;
        background-color: #1c1f23;
        border-color: #1a1e21;
    }
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;

    &:hover {
        color: #fff;
        background-color: #0b5ed7;
        border-color: #0a58ca;
    }
}

a{
    color: #cacaca;
}

html, 
body,
#root {
    background-image: linear-gradient(rgb(53, 90, 125), rgb(2, 185, 199), rgb(53, 90, 125));
    font-family: "Century Gothic", "Microsoft JhengHei", Helvetica, Arial;
    height: 100%;
}

.App{
    background-image: linear-gradient(rgb(53, 90, 125), rgb(2, 185, 199), rgb(53, 90, 125));
    min-height: 600px;
    -webkit-font-smoothing: antialiased;
    height: 100%;
}

.loading {
    display: flex;
    flex-direction: column;
    font-size: 50px;
    font-weight: bold;
    color: #000;
    padding: 20px;
    width: 100%;
    height: 100%;
  
    svg {
      width: 150px;
      height: 150px;
      stroke: #18a6c1;
    }
  
    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 75px;
      height: 100px;
      margin: 0 auto;
      top:25%;
    }
    .lds-ellipsis div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: radial-gradient(#355A7D, #2A6F8D);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }

.react-datepicker{display: table !important; margin: auto; margin-bottom: 10px;}
.react-datepicker__day--selected, .react-datepicker__time-list-item--selected{background-color: #355a7d !important;}
.react-datepicker__time-container, .react-datepicker__time-box{ width: 70px !important;}
.react-datepicker__time-list-item, .react-datepicker__header--time {padding-left: 0 !important; padding-right: 5px !important;}

.myPagination{
    font-size: smaller;
    .muted .page-link{padding-left: 0.1rem; padding-right: 0.1rem;}
    .page-link{color: #000; padding-left: 0.6rem; padding-right: 0.6rem;}
    .page-item.active .page-link{
        background-color: #02B9C7;
        border-color: #00828b;
        -moz-box-shadow:    inset 0 0 10px #008f99;
        -webkit-box-shadow: inset 0 0 10px #008f99;
        box-shadow:         inset 0 0 10px #008f99;
    } 
    svg{
      color: white;
      cursor: pointer;
      flex: auto;
      font-size: 20px;
      margin: 6px;
    }
    .backIcon{float: left; }
    .filterIcon{float: right;}
}

.myMsgBar{
  position: fixed;
  z-index: 2040;
  top: 5px;
  left: 5px;
  right: 5px;
  text-align: center;
  &.alert-success {
    --bs-alert-color: #0f5132;
    --bs-alert-bg: #d1e7dd;
    --bs-alert-border-color: #badbcc;
  }
  &.alert-danger {
    --bs-alert-color: #842029;
    --bs-alert-bg: #f8d7da;
    --bs-alert-border-color: #f5c2c7;
  }
}

.sticky-nav {
    position: sticky;
    top: 0;
    background-color: rgb(53, 90, 125);
    #destinationTitle{color: #cacaca; cursor: pointer;}
    svg{margin-top: -3px;}
}

.btn-remove{background-color: rgb(220,53,69);color: white;}
.btn-submit{background-color: rgb(0, 65, 40);color: white;}
.btn-cancel{background-color: darkgrey;color: white;}
.btn-team-management {
  width: 100%;
}
.myBtn{
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    text-decoration: none;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid transparent;

    &:focus{
        -moz-box-shadow:    0 0 5px #000000;
        -webkit-box-shadow: 0 0 5px #000000;
        box-shadow:         0 0 5px #000000; 
    }

    &:disabled{
        background-color: grey;
    }
}

.myLoadingContainer{
    height: 50vh; text-align: center;
}

#myNav{
  color: #cacaca;
  svg{margin-top: -3px;}
}

#myNav2{
    .dropdown-menu a{ font-size: smaller; white-space: normal;}
    .dropdown-menu{ max-height: 200px; min-width:200px; overflow-y: auto; scrollbar-width: thin;}
    .dropdown-menu::-webkit-scrollbar { width:10px; }
    .dropdown-menu::-webkit-scrollbar-thumb { background: #e8e8e8; border-radius: 0 3px 3px 0;}
    .dropdown-menu::-webkit-scrollbar-track { background-color: #02B9C7; border-radius: 0 3px 3px 0;}
}

#myTopMenu{
  .nav-link{padding: 5px 10px;}
}

#BottomNav{
    text-align: center;
    background-color: #355a7d;
    color: rgba(255, 255, 255, 0.55);
    font-size: x-small;
}

#ClusterSearchAccordion{
    .accordion-button::after{visibility: hidden !important;}
    .list-group-item{
      cursor: pointer;
      svg{max-width: 15px;max-height: 15px;}
    }

    #AllVenuesAccordion{
        .accordion-button::after{visibility: hidden !important;}
    }
}

.KPItitle{
  color: white;
  svg{margin-top: -5px;}
}

.KPIbox{
    padding: 15px 20px;
    margin-bottom: 5px;
    border-radius: 5px !important;
    box-shadow: 0 0 10px #404040;
    &.fatal{ background-color: #000; color: white;}
    &.critical{ background-color: #c83349; color: white;}
    &.alert{ background-color: #feb236; color: #000;}
    &.team-management{ 
      background-color: #ed7d31; 
      color: #000;
      svg {
        width: 45px;
        margin-top: 0;
      }
    }
    &.point-configuration {
      background-color: #2f5597;
      color: #000;
      svg {
        width: 45px;
        margin-top: 0;
      }
    }
    .value{
      font-size: x-large;
    }
    svg{
      margin-top: -5px;
    }
}

// .backIcon{color: white; height: 35px; width: 25px; margin-left: 10px; float: right; cursor: pointer;}
// .filterIcon{color: white; height: 35px; width: 25px; margin-left: 10px; float: right; cursor: pointer;}
.filterTitle{
  color:#355a7d; font-size: large;font-weight: 700; padding-bottom: 5px;
  svg{margin-top: -5px;}
}

.moAlmRecord{
    background-color: #f5f5f5;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    &.fatal{ border-left: 10px solid #000;}
    &.critical{ border-left: 10px solid #c83349;}
    &.alert{ border-left: 10px solid #feb236;}

    .recordCon{
        box-sizing: border-box;
        background-color: #f5f5f5;
        color: #000000;
        transition: .2s;
        margin-left: 5px;
    }

    .imgCon{
        // max-width: 50px;
        max-width: 80px;

        .inactive{visibility: hidden;}
    }

    .ack-icon{cursor: pointer;}
    .status{ white-space: break-spaces;}
    .status.badge.bg-warning{color: black;}
    .name{float: left;}
    .test_only{background-color: #e5e5e5; text-align: center;margin-bottom: 0.5rem; }
}

.almAccordion{
    background-color: initial;
    border-top: 1px solid rgb(209, 209, 209);
    .accordion-item{border-radius: 0; background-color: initial;}
    .accordion-button{margin: 0 auto; padding: 10px 30%; background-color: initial;}
}

.uploaded-files {
  color: #0060B6;

  >div >span {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.myOffcanvas{
    &#schOffcanvas{
        height: 90vh;
        .schTab {
            .nav-link{
                border-radius: 0;
                background-color: grey;
                color: white;
                &.active{background-color: #355a7d;}
            }
            
        }
        .tab-content{
            border-radius: 5px;
            background-color: #F5F5F5;
        }
        .old_sch_dt{color: grey;}
        .new_sch_dt{color: #355a7d; font-weight: 700;}
    }

    &#removeAlmOffcanvas{
        height: 50vh;
    }

    &#almNotesOffcanvas{
        height: 50vh;
        .uploaded-files {
          margin: 15px 0;
          .icon {
            margin-left: 10px;
            cursor: pointer;
            color: #000000;
          }
        }
    }
    &#menuOffcanvas{
      height: 260px;
      svg{margin-top: -3px;}
      .offcanvas-title{font-size: 16px;}
    }
    &#createPrevOffcanvas, &#editPrevOffcanvas{
      height: 100vh;
      .progress_step{
        background-color: rgb(172, 170, 170);
        border-radius: 50%;
        padding-top: 10px;
        width: 50px;
        height: 50px;
        color: white;
        text-align: center;
      }
      .progress_line{
        border: 1px solid rgb(172, 170, 170);
        position: relative;
        height: 2px;
        width: 100%;
        top: 25px;
        z-index: -10;
      }
      .progress_caption{
        font-size: small;
        color: rgb(172, 170, 170);
        text-align: center;
      }
      .active{
        .progress_step{background-color: #00828b;color: white;}
        .progress_line{border: 1px solid #00828b;}
        .progress_caption{color: #000;}
      }
      .step_name{text-decoration:underline;}
      .step_desc{color:#00828b;font-weight: bold;font-size: larger;}
      .btn_next{background-color: #00828b; color: white;width: auto; padding: 5px 20px; border-radius: 3px;}
      .btn_back{background-color: #767a7a;}
      .date_format{font-size: small;}
      .react-datepicker__input-container{
        width: auto;
      }
      .prevent_date_input{
        border-radius: 3px; 
        border: 1px solid rgb(224, 224, 224);
        background-color: rgb(224, 224, 224);
        padding: 5px 7px; 
        text-align: center;
        box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
      }
      .err_msg{color: red;}
      .submit_val{color: #006168}
    }
    &#editPrevOffcanvas{
      height: 70vh;
    }
    &#removePrevOffcanvas{
      height: 45vh;
    }
}

.myPageTab{
  button.nav-link{
    color: rgba(255, 255, 255, 0.55);
  }
}

.approval_center{
  .approval_container{
    .container{
      border: 1px solid black;
      border-radius: 5px;
      background-color: white;
    }

    .file-name {
      font-size: 14px;
      span {
        color: #0060B6;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .files-dotdotdot {
      color: #0060B6;
      cursor: pointer;
    }
    .download-all-col {
      margin-bottom: 10px;
    }
    .download-btn {
      margin-bottom: 20px;
    }
    .approval-center-btn {
      width: 100%;
      max-width: 650px;
    }
  }
  .alarm_lv{
    border-radius: 2px;
    padding: 2px 5px;
    font-size: small;
    &.fatal{ background-color: #000; color: white;}
    &.critical{ background-color: #c83349; color: white;}
    &.alert{ background-color: #feb236; color: #000;}
  }
  .tab-content {
    .filter-icon {
      color: #ffffff;
      font-size: 22px;
      cursor: pointer;
      &.left {
        text-align: left;
      }
      &.right {
        text-align: right;
      }
    }
    .tab-top-bar {
      display: flex;
    }
  }
}

.prevent_maint{
  .prevent_tray{
    padding: 15px 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    font-size: medium;
    background: rgba(0,0,0,.25);
    color: white;
    min-height: 100px;
    text-align: center;
    &.hidden{ opacity: 0.6;background-color: dimgrey;}
    .tray_icon{}
    svg{width: 40px;}
  }
}

.prevent_maint_record{
  .s_desc{
    font-size: x-small;
    color: #929292;
  }
  .status_started{color: #198754;}
  .status_in24hr{color: #ff7b25;}
  .backIcon{left: 10px; margin-top: 10px; color: white; font-size: 20px;}
  .page_title{
    position: relative; 
    margin-top: -30px;
    color: #cacaca;
    display: initial;
    padding: 5px 15px;
    border-radius: 0 0 5px 5px;
    font-size: larger;
    svg{display: initial; width: 25px;}
  }
}

#decideRemovalOffcanvas{
  height: 60vh;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.clear-col {
  padding-left: 0;
  .clear-btn {
    width: 100%;
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.spaceline{
  border: 1px solid white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.datepicker-modal {
  z-index: 1000000 !important;
}

.date-field {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
}

.date-label {
  align-self: center;
  padding: 0;
}

.datepicker .datepicker-navbar-btn {
  text-decoration: none;
}

.filter-btn {
  font-size: 16px;
}
  
.teamManagement {
  color: #fff;
  .label {
    align-self: center;
  }
  .remark {
    font-size: 14px;
  }
  .error-msg {
    color: red;
    font-size: 14px;
  }
  .spaceline {
    margin: 20px 0;
  }
  .table-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  table {
    color: #fff;
    text-align: center;
    tr {
      border-width: 2px 0;
      th, td {
        border-width: 0 2px;
      }
    }
  }
  .message {
    text-align: center;
    margin-bottom: 15px;
    &.error {
      color: red;
    }
  }
  .accordion {
    margin: 20px 0;
    .accordion-item {
      border: none;
      background-color: transparent;
      .accordion-header{
        button {
          color: white;
          padding: 10px;
          border-radius: 4px;
          box-shadow: none;
        }
      }
      .accordion-body {
        color: white;
        .contact-label {
          margin-top: 5px;
        }
        .contact-title {
          margin-top: 15px;
          svg {
            margin-right: 5px;
          }
        }
      }
    }

    &.urgent-accordion button {
      background-color: red;
    }
    &.emergency-accordion button {
      background-color: #000;
    }
    &.alarm-request-accordion button {
      background-color: #d2691e;
    }
    &.alarm-approval-accordion button {
      background-color: rgb(0, 65, 40);
    }
  }
}

#teamManagementBoard {
  .container {
    padding-bottom: 50px;
  }
}

.btn.btn-edit {
  padding: 3px 10px;
  background-color: chocolate;
  color: #fff;
}

.pointConfiguration {
  .no-record {
    text-align: center;
    color: #fff;
  }
}