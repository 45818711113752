.login-page { display:flex; align-items: center; background:#f6f6f6;background-color:#f6f6f6; height: 100%;}


.login-board {width:100%; max-width:700px; display:flex; margin: auto; background:#ffffff; box-shadow: 0px 0px 10px #ccc; border-radius:5px; overflow:hidden;}
.logo-area { flex:0.5; padding: 80px 50px; align-items: center; text-align:center;background-color: #24455a;}
.logo-area .logo { flex:1; margin-bottom:10px; color:#c1222b;  }
.logo-area .logo svg {width:40%;}
.logo-area .title { font-family:Arial; font-size:30px; font-weight: bold; color:#ffffff;}

.login-form { flex:0.6; padding: 80px 50px; text-align:center;  }
.login-form .caption {color:#24455a;}
.login-form .btn { width:100%; padding:10px 15px;border:0px; background-color: #02B9C7; outline:none; box-shadow: none; color:#ffffff; border-radius: 5px; font-weight: 600; font-size: medium;}

.login-header .logo-row-wrapper {
    margin: 0px 0 30px 0;
}
.login-header .logo-row-title {
    width: 100%;
    max-width: 700px;
    display: flex;
    margin: auto;
}
.login-header .logo-row-wrapper .logo-title {
    margin: auto;
}
.login-header .logo-row-wrapper .logo-row-title {
    margin: auto;
    margin-bottom: 10px;
    width: 116px;
}
.login-header .logo-row-wrapper svg{
    height: 36px;
    color: #c1222b;
}
.login-page {
    margin-top: auto;
margin-bottom: auto;
    background: radial-gradient(at 50% 30%, rgba(66,194,203,1) 0%, rgba(54,117,160,1) 74%, rgba(52,88,122,1) 100%);
}
.logo-title {
    font-weight: 900;
    color: white;
    font-size: x-large;
    letter-spacing: 1px;
    line-height: initial;
    padding-left: 5px;
}
.login-board {
    width: 100%;
    max-width: 500px;
    display: flex;
    margin: auto;
    background: #ffffff;
    box-shadow: none;
    border-radius: 10px;
    overflow: hidden;
}
.logo-row-fullname {
    text-align: center;
    color: white;
    font-size: medium;
    font-weight: 600;
}
.logo-row-fullname .name1 {
    font-size: x-large
}
.logo-row-fullname .name2 {
    font-size: large
}
.login-form {
    margin: auto;
    padding: 35px 10px;
}

#login_btn {
    cursor: pointer;
}

@media only screen and (min-width: 4000px) {
    .login-board {
        transform: scale(4) translate(0, 30%);
        transition: 1s;
    }
    .login-header {
        transform: scale(4) translate(0, -60%);
        transition: 1s;
    }
  }

.ant-input-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 16px;
}

.ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 8px;
    color: #000000;
    font-size: small;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #b8b8b8;
    border-radius: 8px;
    transition: all .3s;
    display: inline-flex;
}
.ant-input-prefix {
    margin-right: 4px;
}
.ant-input-prefix, .ant-input-suffix {
    display: flex;
    flex: none;
    align-items: center;
}
.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.anticon svg {
    display: inline-block;
}
svg:not(:root) {
    overflow: hidden;
}
.anticon svg {
    display: inline-block;
}

.anticon>* {
    line-height: 1;
}
.anticon > * {
    line-height: 1;
}

.ant-input-affix-wrapper>input.ant-input {
    padding: 0;
    border: none;
    outline: none;
    font-size: medium;
}

.ant-input:placeholder-shown {
    text-overflow: ellipsis;
}
a, area, button, [role=button], input:not([type=range]), label, select, summary, textarea {
    touch-action: manipulation;
}
input[type=text], input[type=password], input[type=number], textarea {
    -webkit-appearance: none;
}
.ant-input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
}
.ant-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 6px 11px;
    color: #b8b8b8;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #b8b8b8;
    border-radius: 10px;
    transition: all .3s;
}
.ant-input::placeholder {
    color: #b8b8b8;
    font-size: small;
}

.ant-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-flex;
    align-items: baseline;
    line-height: unset;
    cursor: pointer;

    float: left;
}

.ant-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    top: .2em;
    line-height: 1;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
}

.ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all .3s;
}
.ant-checkbox-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect .36s ease-in-out;
    animation: antCheckboxEffect .36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    content: "";
}
.ant-checkbox+span {
    padding-left: 8px;
    color: #B8B8B8;
    font-size: medium;
}
.ant-checkbox-wrapper:after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: "\a0";
}

.login-form .field {
    margin-bottom: 15px;
}

*, *:before, *:after {
    box-sizing: border-box;
}